<template>
  <div class="bank-book-history">
    <div class="wrapper">
      <div class="title font-md">
        <b>입출금 내역</b>
      </div>
      <div class="desc font-sm">조회하실 날짜를 선택해주세요.</div>
      <div class="act">
        <button class="btn btn-default font-xs" @click="load(true)" :disabled="!state.loaded" title="새로고침">
          <i class="fa fa-refresh"></i>
        </button>
      </div>
      <div class="period">
        <div class="row">
          <div class="col">
            <Date componentNameSuffix="S" class="font-sm" placeholder="시작일" :value.sync="state.args.searchKeywordFrom" :func="() => load(true)" :disabled="!state.loaded"/>
          </div>
          <div class="col">
            <Date componentNameSuffix="E" class="font-sm" placeholder="종료일" :value.sync="state.args.searchKeywordTo" :func="() => load(true)" :disabled="!state.loaded"/>
          </div>
        </div>
      </div>
      <div class="content">
        <table class="table font-sm">
          <tr v-for="d in state.details" :key="d.accountDetailSeq">
            <td class="date color-secondary">
              <div>
                <span>{{ d.transDate }}</span>
              </div>
            </td>
            <td>
              <span>{{ d.accountMemo }}</span>
            </td>
            <td class="text-right">
              <span>{{ $lib.getNumberFormat(d.amount) }}원</span>
              <br/>
              <span class="color-secondary">{{ d.detailTypeText }}</span>
            </td>
          </tr>
          <template v-if="!state.loaded">
            <tr class="skeleton" v-for="i in 3" :key="i">
              <td class="date">
                <span>Please wait a moment</span>
              </td>
              <td>
                <span>Wait a moment</span>
              </td>
              <td class="text-right">
                <span>Please wait a moment</span>
              </td>
            </tr>
          </template>
          <tr v-else-if="state.details.length < state.count">
            <td colspan="3" class="more" @click="load(false,true)">
              <div>더 보기</div>
            </td>
          </tr>
        </table>
        <div class="pt-15" v-if="state.loaded && !state.details.length">
          <NoData/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import Date from "@/components/Date";
import NoData from "@/components/NoData";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "modalBankBookHistory";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {NoData, Date},
  setup() {
    const component = new Component(() => {
      const date = new window.Date();
      state.args.searchKeywordTo = lib.getDateFormat(date, "yyyyMMdd");
      date.setDate(date.getDate() - 30);
      state.args.searchKeywordFrom = lib.getDateFormat(date, "yyyyMMdd");

      load(true);
      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      loaded: false,
      count: 0,
      details: [],
      args: {
        pageIndex: 1,
        searchKeywordFrom: "",
        searchKeywordTo: "",
      }
    });

    const load = (init, more) => {
      if (init) {
        state.args.pageIndex = 1;
        state.details = [];
      } else if (more) {
        state.args.pageIndex += 1;
      }

      state.loaded = false;
      http.get(`/api/investors/${store.state.investor.investorSeq}/account-details`, state.args).then(({data}) => {
        state.loaded = true;
        state.count = data.body.accountDetailsListCnt;

        if (init) {
          state.details = data.body.accountDetailsList;
        } else {
          for (let d of data.body.accountDetailsList) {
            state.details.push(d);
          }
        }
      }).catch(httpError(() => {
        store.commit("closeModal", {name: component.name});
      }));
    };

    return {component, state, load};
  }
});
</script>

<style lang="scss" scoped>
.bank-book-history {
  background: #fff;
  padding: $px25;
  min-height: $px450;

  > .wrapper {
    position: relative;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      margin-bottom: $px25;
      color: #5c5c5c;
    }

    .act {
      position: absolute;
      top: 0;
      right: 0;

      .btn {
        padding: $px10 $px14;
      }
    }

    .period {
      position: relative;

      &:after {
        content: "~";
        position: absolute;
        top: $px10;
        left: 50%;
        margin-left: -4px;;
      }
    }

    .content {
      padding-top: $px5;

      table {
        table-layout: fixed;

        tr {
          td {
            border: 0;
            border-top: $px1 solid #eee;
            padding-left: $px5;
            padding-right: $px5;
            padding-top: $px20;

            &.date {
              div {
                width: $px90;
                color: #666;
              }
            }

            &.more {
              padding-bottom: 0;

              > div {
                text-align: center;
                padding: $px15;
                cursor: pointer;
                border: $px1 solid #eee;
                background: $colorBackground;

                &:hover {
                  background: #eee;
                }
              }
            }
          }

          &:first-child {
            td {
              border-top: 0
            }
          }
        }
      }
    }
  }

  > .wrapper .content table tr.skeleton td span {
    @include skeleton;
  }
}
</style>